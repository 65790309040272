'use client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Link from 'next/link';
import { useRouter } from 'next/navigation';
import { signIn } from 'next-auth/react';

import AuthForm from '@components/AuthForm/AuthForm';

interface Data {
  email?: string;
  password?: string;
}

export default function LoginForm() {
  const { t } = useTranslation('common');
  const router = useRouter();
  const [error, setError] = useState('');

  const handleFormSubmit = async (data: Data) => {
    const response = await signIn('credentials', {
      email: data.email,
      password: data.password,
      redirect: false,
    });
    if (!response?.error) {
      router.push('/assignments');
      router.refresh();
    } else {
      if (response.status === 401) {
        setError('Your email or password is incorrect');
      }
    }
  };

  return (
    <>
      {error && <p>{error}</p>}
      <AuthForm
        title={t('login.title')}
        onSubmit={handleFormSubmit}
        buttonText={t('login.login')}
      />
      <div>
        <Link href="/request-reset-password">
          <button>{t('login.forgotPassword')}</button>
        </Link>
      </div>
    </>
  );
}
