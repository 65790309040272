import { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Data {
  email: string;
  password: string;
}

interface AuthFormProps {
  title: string;
  buttonText: string;
  onSubmit: (data: Data) => void;
}

export default function AuthForm({ title, buttonText, onSubmit }: AuthFormProps) {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState({
    email: 'test@gmail.com',
    password: '00000000',
  });
  const handleFormSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(formData);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleFormSubmit}>
      <h1>{title}</h1>
      <input
        type="email"
        placeholder={t('login.enterEmail')}
        name="email"
        value={formData.email}
        onChange={handleInputChange}
        required
      />
      <input
        type="password"
        placeholder={t('login.enterPassword')}
        name="password"
        value={formData.password}
        required
        onChange={handleInputChange}
      />
      <button type="submit">{buttonText}</button>
    </form>
  );
}
