'use client';

import LoginForm from '@components/LoginForm/LoginForm';

export default function LoginPage() {
  return (
    <div>
      <LoginForm />
    </div>
  );
}
